import {
  useContext,
  createContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";

import useAbortSignal from "../hooks/useAbortSignal";

import AuthServices from "../services/authServices";
import { errorToast } from "../util/toastNotifications";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [user, setUser] = useState(null);

  const { signal } = useAbortSignal();
  const history = useHistory();

  const login = useCallback(
    async (payload) => {
      setIsLoading(true);
      setErrorMsg("");

      try {
        const promiseResult = await AuthServices.loginService(payload, signal);
        if (promiseResult) {
          const { auth_info, user_info } = promiseResult;

          Cookies.set("_sid", auth_info._sid, {
            secure: process.env.NODE_ENV === "production",
            expires: 0.5,
          });

          setUser(user_info);
        }
      } catch (error) {
        if (error.message === "Email not verified") {
          history.push("/verify-email");
        } else {
          setErrorMsg(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [history, signal]
  );

  const logout = useCallback(async () => {
    setIsLoading(true);

    try {
      const isLoggedOut = await AuthServices.logoutService(signal);
      if (isLoggedOut) {
        Cookies.remove("_sid");
        Cookies.remove("__stripe_mid");
        Cookies.remove("__stripe_sid");

        localStorage.clear();

        setUser(null);
      }
    } catch (error) {
      errorToast("Unable to logout");
      console.error("Error logging out", error);
    } finally {
      setIsLoading(false);
    }
  }, [signal]);

  useEffect(() => {
    async function checkLogin() {
      try {
        setIsLoading(true);
        const { user_info } = await AuthServices.checkLogin();
        setUser(user_info);
      } catch (e) {
        setUser(null);
      } finally {
        setIsLoading(false);
      }
    }

    checkLogin();
  }, []);

  const values = {
    user,
    setUser,
    isLoading,
    errorMsg,
    login,
    logout,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export const useAuthInfo = () => {
  return useContext(AuthContext);
};
